
.article-detail-page {
    .article-detail-wrap {
        display: flex;
        justify-content: space-between;
    }
    .com-title {
        height: 45px;
        line-height: 45px;
        border-bottom: 2px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 11px;
                margin-left: 3px;
            }
            h5 {
                height: 45px;
                line-height: 45px;
                font-size: 24px;
                color: #000000;
                border-bottom: 3px solid #bd1a2d;
            }
        }

        ul {
            display: flex;
            li {
                font-size: 18px;
                margin-right: 18px;
                cursor: pointer;
                line-height: 36px;
                color: #333;
            }
        }
        span {
            font-size: 14px;
            color: #404040;
            cursor: pointer;
            img {
                width: 4px;
                height: 8px;
                margin-left: 10px;
            }
        }
    }
    .left-article-detail {
        width: 830px;
        .article-detail {
            padding: 0 28px 21px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            h5 {
                padding-top: 10px;

                span {
                    // display: block;
                    // width: 144px;
                    height: 41px;
                    line-height: 46px;
                    font-size: 20px;
                    color: #000;
                    margin-bottom: 10px;
                    padding-top: 8px;
                    border-bottom: 3px solid #bd1a2d;
                    img {
                        width: 26px;
                        margin-top: 11px;
                        vertical-align: top;
                    }
                    span >>> .role4 {
                        vertical-align: middle;
                        width: 20px !important;
                        height: 20px !important;
                        margin-top: -2px;
                    }
                }

            }
            .top-article-detail {
                display: flex;
                justify-content: center;
                .info-article {
                    width: 632px;
                    min-height: 150px;
                    padding-top: 10px;
                    position: relative;

                    .info {
                        display: flex;
                        justify-content: center;
                    }
                    p {
                        font-size: 14px;
                        // color: #666;
                        line-height: 30px;
                        color: #000;
                        span {
                            font-size: 14px;
                            // color: #333;
                            margin-right: 18px;
                            color: #000;
                        }
                        // img {
                        //     width: 24px;
                        //     height: 24px;
                        //     margin-right: 24px;
                        //     cursor: pointer;
                        //     vertical-align: middle;
                        // }
                        // img:first-child {
                        //     margin-left: 9px;
                        // }
                    }
                    p:first-child {
                        width: 320px;
                    }
                    p:nth-child(2) {
                        flex: 1;
                    }

                    // .img-wrap {
                    //     display: flex;

                    //     .com-img {
                    //         width: auto;
                    //         position: relative;
                    //         margin-right: 24px;
                    //         img {
                    //             width: 24px;
                    //             height: 24px;
                    //             margin-top: 8px;
                    //             margin-bottom: 20px;
                    //             cursor: pointer;
                    //             vertical-align: middle;
                    //         }

                    //         .com-img-wrap {
                    //             position: absolute;
                    //             left: 50%;
                    //             top: 42px;
                    //             transform: translateX(-50%);
                    //             width: 80px;
                    //             box-sizing: border-box;
                    //             text-align: center;
                    //             line-height: 30px;
                    //             font-size: 14px;
                    //             border-radius: 4px;
                    //             display: none;
                    //             border: 1px solid #eee;
                    //             cursor: pointer;
                    //             color: #333;
                    //         }
                    //         .code-img-wrap5 {
                    //             width: 90px;
                    //             height: 90px;
                    //             text-align: center;
                    //             line-height: 90px;
                    //             background: #fff;
                    //             padding-left: 6px;
                    //             padding-top: 6px;
                    //             div {
                    //                 width: 76px;
                    //                 height: 76px;
                    //             }
                    //         }
                    //         .com-img-wrap::before {
                    //             box-sizing: content-box;
                    //             width: 0px;
                    //             height: 0px;
                    //             position: absolute;
                    //             top: -16px;
                    //             // right: 41px;
                    //             left: 50%;
                    //             transform: translateX(-50%);
                    //             padding: 0;
                    //             border-bottom: 8px solid #ffffff;
                    //             border-top: 8px solid transparent;
                    //             border-left: 8px solid transparent;
                    //             border-right: 8px solid transparent;
                    //             display: block;
                    //             content: '';
                    //             z-index: 12;
                    //         }
                    //         .com-img-wrap::after {
                    //             box-sizing: content-box;
                    //             width: 0px;
                    //             height: 0px;
                    //             position: absolute;
                    //             top: -18px;
                    //             // right: 40px;
                    //             left: 50%;
                    //             transform: translateX(-50%);
                    //             padding: 0;
                    //             border-bottom: 9px solid #cccccc;
                    //             border-top: 9px solid transparent;
                    //             border-left: 9px solid transparent;
                    //             border-right: 9px solid transparent;
                    //             display: block;
                    //             content: '';
                    //             z-index: 10;
                    //         }
                    //         // div::before {
                    //         //     content: '';
                    //         //     display: block;
                    //         //     width: 0;
                    //         //     height: 0;
                    //         //     border-left: 5px solid transparent;
                    //         //     border-right: 5px solid transparent;
                    //         //     border-bottom: 10px solid red;
                    //         //     position: absolute;
                    //         //     top: -9px;
                    //         //     left: 50%;
                    //         //     transform: translateX(-50%);

                    //         // }
                    //     }
                    //     .com-img:hover .com-img-wrap {
                    //         display: block;
                    //     }
                    //     .com-img:first-child {
                    //         margin-left: 9px;
                    //         // div {
                    //         //     background: red;
                    //         // }
                    //     }
                    // }
                    .btn-wrap {
                        // position: absolute;
                        // bottom: 0;
                        margin-top: 20px;
                        button {
                            width: 90px;
                            height: 30px;
                            background: #ff8600;
                            font-size: 14px;
                            color: #fff;
                            margin-right: 20px;
                            border: none;
                            border-radius: 4px;
                        }
                    }
                }
                .er-code {
                    flex: 1;

                    div.er-code-wrap {
                        box-sizing: border-box;
                        width: 140px; //160px;
                        height: 140px; //160px;
                        padding: 10px 10px;

                        box-sizing: border-box;
                        background: url(../assets/img/recticon.png);
                        background-size: 100% 100%;
                        background-position: 0 0;
                        .er {
                            width: 119px;
                            height: 120px;
                            margin-bottom: 6px;
                            overflow: hidden;
                            // margin-left: 10px;
                        }
                        p {
                            text-align: center;
                            color: #bd1a2d;
                            font-size: 12px;
                            line-height: 12px;
                        }
                        img {
                            display: block;
                            width: 134px;
                            height: 135px;
                        }
                    }
                }
            }
            .bottom-article-detail {
                ul.nav-article {
                    display: flex;
                    height: 42px;
                    border-bottom: 2px solid #bd1a2d;
                    margin-top: 23px;
                    margin-bottom: 18px;
                    li {
                        width: 140px;
                        height: 42px;
                        text-align: center;
                        line-height: 42px;
                        font-size: 18px;
                        color: #333;
                        background: #fff;
                        cursor: pointer;
                    }
                    li.active {
                        color: #fff;
                        background: #bd1a2d;
                    }
                }
                .article-content {
                    p {
                        line-height: 26px;
                        height: 184px;
                        font-size: 16px;
                        color: #666;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 7;
                        line-clamp: 7;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
        .article-msg {
            margin-top: 22px;
            padding: 20px 28px 24px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-wrap {
                position: relative;
                margin-bottom: 15px;
                h6 {
                    font-size: 16px;
                    color: #bd1a2d;
                    position: absolute;
                    left: 0;
                    line-height: 24px;
                }
                .com-p {
                    font-size: 14px;
                    color: #333;
                    line-height: 24px;
                    text-indent: 20px;
                    text-indent: 46px;
                    // padding-top: 2px;
                }
            }
            .abstract-cn-wrap {
                position: relative;
                h6 {
                }
            }
            .keywords-wrap {
                // margin-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                h6 {
                    position: inherit;
                }
                .com-p {
                    text-indent: 0px;
                    margin-right: 10px;
                }
            }
            .abstract-en-wrap {
                position: relative;
                .com-p {
                    text-indent: 77px;
                }
            }

            .abstract-cn-info,
            .abstract-en-info {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                line-height: 24px;
            }
            .abstract-cn-btn,
            .abstract-en-btn {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 24px;
                line-height: 24px;
                text-align: center;
                cursor: pointer;
                background: none repeat scroll 0 0 #ffffff;
                color: #727171;
                display: none;
            }

            .keywords-en-wrap {
                // margin-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                h6 {
                    position: inherit;
                }
                .com-p {
                    text-indent: 0px;
                    margin-right: 15px;
                }
            }
            .author-intro {
                display: block;
                // h6 {
                //     position: inherit;
                // }
                position: relative;
                .com-p {
                    text-indent: 76px;
                }
                .author-intro-info {
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                    line-height: 24px;
                }
                .author-btn {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 24px;
                    line-height: 24px;
                    text-align: center;
                    cursor: pointer;
                    background: none repeat scroll 0 0 #ffffff;
                    color: #727171;
                    display: none;
                }
            }
            .article-body {
                margin-top: 25px;
                background: #f8f8f8;
                padding: 0 10px 11px;
                h6 {
                    font-size: 16px;
                    font-weight: Bold;
                    text-align: center;
                    color: #bd1a2d;
                    line-height: 32px;
                }
                .article-content {
                    font-size: 14px;
                    font-family: Microsoft YaHei, Microsoft YaHei-Normal;
                    font-weight: Normal;
                    text-align: left;
                    color: #333333;
                    line-height: 28px;
                }
                .btn {
                    text-align: center;
                    margin-top: 10px;
                    button {
                        width: 90px;
                        height: 30px;
                        background: #ff8600;
                        font-size: 14px;
                        font-weight: Bold;
                        text-align: center;
                        color: #ffffff;
                        border: none;
                        border-radius: 4px;
                    }
                }
            }
        }

        .related-recommend {
            margin-top: 24px;
            margin-bottom: 24px;
            padding: 0 0 0 28px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            ul {
                display: flex;
                flex-wrap: wrap;
                margin: 18px 0 20px;
                li {
                    width: 386px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    p {
                        color: #333;
                        font-size: 16px;
                        line-height: 37px;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                    }
                    span {
                        display: block;
                        line-height: 37px;
                        color: #666;
                        font-size: 16px;
                    }
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 18px;
                    line-height: 37px;
                    background: #ffac13;
                }
                li:nth-child(2n + 1) {
                    border-right: 1px solid #e6e6e6;
                    padding-right: 4px;
                    p {
                        width: 280px;
                    }
                    span {
                        width: 80px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                li:nth-child(2n + 2) {
                    margin-left: 18px;
                    width: 382px;
                    p {
                        width: 276px;
                    }
                    span {
                        width: 80px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    .right-article-detail {
        width: 316px;

        .article-source-wrap {
            padding: 0 18px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-title {
                div {
                    img {
                        height: 29px;
                    }
                }
            }
            .article-source {
                display: flex;
                justify-content: space-between;
                padding-top: 14px;
                img {
                    width: 106px;
                    height: 142px;
                    margin-bottom: 26px;
                    box-shadow: 0px 0px 6px 1px rgba(15, 11, 5, 0.2);
                }
                .article-source-main {
                    width: 162px;
                    h5,
                    p:nth-child(2) {
                        width: 162px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    h5 {
                        font-size: 16px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                    p {
                        font-size: 14px;
                        color: #666;
                        line-height: 22px;
                        span {
                            font-size: 14px;
                            color: #666;
                            line-height: 22px;
                        }
                    }
                    p:nth-child(3) {
                        width: 162px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
        .anthology-catalog-wrap {
            padding: 0 18px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-top: 24px;
            .com-title {
                div {
                    img {
                        height: 29px;
                    }
                }
            }
            .anthology-catalog {
                ul {
                    padding: 10px 0 14px;
                    li {
                        line-height: 37px;
                        height: 37px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-left: 10px;
                        position: relative;
                        font-size: 16px;
                        color: #333;
                        width: 278px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                        box-sizing: border-box;
                    }
                    li >>> .role4 {
                        vertical-align: middle;
                        width: 16px !important;
                        height: 16px !important;
                        margin-top: -2px;
                    }
                    li::before {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ffac13;
                        position: absolute;
                        left: 0;
                        top: 18px;
                    }
                }
            }
        }
    }
}

// .author-btn {
//     display: none;
// }
